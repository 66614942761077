var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-divider", { attrs: { "content-position": "right" } }, [
        _c(
          "div",
          {
            on: {
              click: function ($event) {
                _vm.drawer = true
              },
            },
          },
          [
            _c("el-image", {
              staticStyle: { width: "180px", height: "20px" },
              attrs: { src: require("@/assets/logo/aiLong.png"), fit: "fill" },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-drawer",
        {
          staticClass: "newheader",
          attrs: {
            visible: _vm.drawer,
            direction: _vm.direction,
            "before-close": _vm.handleClose,
            "append-to-body": true,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "chat-window" },
            [
              _c(
                "el-scrollbar",
                {
                  staticStyle: { height: "75vh" },
                  attrs: { wrapStyle: [{ "overflow-x": "hidden" }] },
                },
                [
                  _c(
                    "div",
                    { staticClass: "message-container" },
                    _vm._l(_vm.messages, function (message) {
                      return _c(
                        "div",
                        { key: message.id, staticClass: "message" },
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 16, offset: 8 } }, [
                                message.mine
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "message-text mine",
                                            attrs: { span: 18 },
                                          },
                                          [_vm._v(" " + _vm._s(message.text))]
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c("el-avatar", {
                                              attrs: {
                                                size: 50,
                                                src: require("@/assets/logo/ailogo.jpg"),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 16 } }, [
                                !message.mine
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c("el-avatar", {
                                                  attrs: {
                                                    size: 50,
                                                    src: require("@/assets/logo/ailogo.jpg"),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c("el-col", {
                                              staticClass: "message-text",
                                              attrs: { span: 18 },
                                              domProps: {
                                                innerHTML: _vm._s(message.text),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "10px" },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "textarea",
                          rows: 6,
                          placeholder: "请输入内容",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            return _vm.handleKeyCode($event)
                          },
                        },
                        model: {
                          value: _vm.form.desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "desc", $$v)
                          },
                          expression: "form.desc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buttom-flow" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.bindEnter } },
                    [_vm._v("发送")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }