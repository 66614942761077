<template>
    <div>
        <el-divider content-position="right">
            <div @click="drawer = true">
                <!-- <el-avatar :size="50" :src="require('@/assets/logo/ai.png')" placement="bottom"> </el-avatar> -->
                <el-image style="width: 180px; height: 20px" :src="require('@/assets/logo/aiLong.png')" fit="fill"></el-image>
            </div>
        </el-divider>
        <el-drawer :visible.sync="drawer" :direction="direction" :before-close="handleClose" :append-to-body="true" :destroy-on-close="true" class="newheader">
            <div class="chat-window">
                <el-scrollbar style="height: 75vh" :wrapStyle="[{ 'overflow-x': 'hidden' }]">
                    <!-- 显示聊天消息的容器 -->
                    <div class="message-container">
                        <div v-for="message in messages" :key="message.id" class="message">
                            <el-row>
                                <el-col :span="16" :offset="8">
                                    <div v-if="message.mine">
                                        <el-col :span="18" class="message-text mine"> {{ message.text }}</el-col>
                                        <el-col :span="6"> <el-avatar :size="50" :src="require('@/assets/logo/ailogo.jpg')"></el-avatar> </el-col>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="16">
                                    <div v-if="!message.mine">
                                        <el-row>
                                            <el-col :span="6"> <el-avatar :size="50" :src="require('@/assets/logo/ailogo.jpg')"></el-avatar> </el-col>
                                            <el-col :span="18" class="message-text" v-html="message.text"></el-col>
                                        </el-row>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </el-scrollbar>

                <!-- 输入消息的表单 -->
                <el-form ref="form" :model="form" label-width="10px">
                    <el-form-item>
                        <el-input type="textarea" v-model="form.desc" style="width: 100%" :rows="6" placeholder="请输入内容" @keydown.native="handleKeyCode($event)"> </el-input>
                    </el-form-item>
                </el-form>
                <div class="buttom-flow">
                    <el-button type="text" @click="bindEnter">发送</el-button>
                </div>

                <!-- <form @submit.prevent="sendMessage" class="input-form">
                    <input v-model="inputText" type="textarea" style="width: 80%; height: 100px" placeholder="输入消息" />
                    <button type="submit">发送</button>
                </form> -->
            </div>

            <!-- <JwChat-index
                :config="json"
                :toolConfig="toolConfig"
                :taleList="taleList"
                @enter="bindEnter"
                v-model="inputMsg"
                :showRightBox="false"
                scrollType="scroll"
                width="100%"
                height="93vh"
            /> -->
        </el-drawer>
    </div>
</template>

<script>
import { Message } from 'element-ui'
import { postMessage } from '@/api/ai'
export default {
    name: 'ZRDoc',
    data() {
        return {
            json: {
                img: '', // 头像
                name: '臻贝医疗',
                dept: '', // 下边文字
            },
            toolConfig: {
                //show: ['file', 'history', 'img', ['文件1', '', '美图']], // 二级数组中放自定义名称
                showEmoji: false, // 是否显示表情图标
            },
            value: '',
            txt: '',
            show: false,
            num: -1,
            dialogVisible: false,
            drawer: false,
            direction: 'rtl',
            filterFruitList: [],
            message: '',
            scrollType: 'noroll', // scroll  noroll 俩种类型
            placeholder: '欢迎使用JwChat...',
            inputMsg: '',
            taleList: [],
            tool: {
                callback: this.toolEvent,
            },
            inputText: '',
            messages: [{ id: 1, text: '欢迎使用AI智能指导语,请输入想查询的问题吧！！！', mine: false }],
            form: {},
        }
    },
    mounted() {},
    methods: {
        goto() {
            this.dialogVisible = true
        },
        send() {
            if (this.txt == '') {
                this.$toast.fail('输入内容')
                return
            }
            postMessage({ content: this.txt }).then((res) => {
                let x = JSON.parse(res.data)
                console.log(x.result)
                this.message = x.result
                console.log(res.data)
            })
        },
        handleClose(done) {
            done()
        },
        bindEnter(e) {
            console.log(e)
            const msg = this.form.desc
            if (!msg) return
            const msgObj = {
                id: new Date(),
                text: msg,
                mine: true,
            }
            const sikao = {
                id: new Date(),
                text: '正在思考',
                mine: false,
            }
            this.messages.push(msgObj)
            this.messages.push(sikao)
            this.getAsw(msg)
        },
        getAsw(val) {
            this.form.desc=""
            postMessage({ content: val }).then((res) => {
                let x = JSON.parse(res.data)
                console.log(x.result)
                this.message = x.result
                console.log(res.data)
                const msgObj = {
                    id: new Date(),
                    text: this.message,
                    mine: false,
                }
                this.messages.pop()
                this.messages.push(msgObj)
                
            })
        },
        openAI() {
            console.log('打开AI界面')
        },
        handleKeyCode(event) {
            if (event.keyCode == 13) {
                if (!event.ctrlKey) {
                    this.bindEnter()
                } else {
                    message += '\n'
                }
            }
        },
    },
}
</script>
<style>
.newheader .el-drawer__header {
    margin-bottom: 0px;
}
</style>
<style scoped>
.chat-window {
    margin: 25px;
}

.message-container {
    margin-bottom: 10px;
}

.message {
    padding: 5px;
    margin-bottom: 5px;
}

.message-text {
    padding-top: 15px;
    border: 0ch;
    border: thin solid;
    border-radius: 5px;
    padding: 5px;
    margin-top: 25px;
}

.mine {
    background-color: lightblue;
}
.buttom-flow {
    position: absolute;
    z-index: 2;
    top: 93%;
    left: 86%;
}
</style>
