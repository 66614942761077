var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout-navbars-breadcrumb-user-news" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "content-box" },
      [
        _vm.noticeList.length > 0
          ? _vm._l(_vm.noticeList, function (v, k) {
              return _c("div", { key: k, staticClass: "content-box-item" }, [
                _c("div", [_vm._v(_vm._s(v.noticeTitle))]),
                _c("div", {
                  staticClass: "content-box-msg",
                  domProps: { innerHTML: _vm._s(v.noticeContent) },
                }),
                _c("div", { staticClass: "content-box-time" }, [
                  _vm._v(_vm._s(v.updateTime)),
                ]),
              ])
            })
          : _c("div", { staticClass: "content-box-empty" }, [_vm._m(1)]),
      ],
      2
    ),
    _vm.noticeList.length > 0
      ? _c(
          "div",
          { staticClass: "foot-box", on: { click: _vm.onGoToGiteeClick } },
          [_vm._v("前往通知中心")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "head-box" }, [
      _c("div", { staticClass: "head-box-title" }, [_vm._v("通知")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-box-empty-margin" }, [
      _c("i", { staticClass: "el-icon-s-promotion" }),
      _c("div", { staticClass: "mt15" }, [_vm._v("全部已读")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }