import Vue from 'vue'

import Cookies from 'js-cookie'

import Element from 'element-ui'

Element.Select.computed.readonly = function () {
    const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode))
    return !(this.filterable || this.multiple || !isIE) && !this.visible
}

import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import './assets/styles/element-variables.scss'
import '@/assets/styles/index.scss' // global css
import './assets/styles/detail.scss'

import App from './App'
import store from './store'
import router from './router'
import permission from './directive/permission'
import plugins from './plugins' // plugins
import signalR from '@/utils/signalR'

import './assets/icons' // icon
import './permission' // permission control
import { getDicts } from '@/api/system/dict/data'
import { getConfigKey } from '@/api/system/config'
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, download, handleTree, handleDict, timerConversion, dataURLtoBlob } from '@/utils/ruoyi'
//分页组件
import Pagination from '@/components/Pagination'
//自定义表格工具扩展
import RightToolbar from '@/components/RightToolbar'
// 富文本组件
import Editor from '@/components/Editor'
// 字典标签组件
import DictTag from '@/components/DictTag'
// 上传图片
import UploadImage from '@/components/UploadImage/index'
// 上传文件nom
import UploadFile from '@/components/FileUpload/index'
//打印
import Print from 'vue-print-nb'
//echarts
import * as echarts from 'echarts'
//全局引用jquery
import jquery from 'jquery'
//医院名称组件
import HospitalName from '@/components/HospitalName'

import htmlToPdf from '@/utils/htmlToPdf.js'

import vueEsign from 'vue-esign'

//引入组件库
import jvuewheel from '@jyeontu/jvuewheel'
//引入样式
import '@jyeontu/jvuewheel/lib/jvuewhell.css'
Vue.use(jvuewheel);

import JwChat from 'jwchat';
Vue.use(JwChat)

//AI组件
import ZrDoc from '@/components/Zr/Doc'
// 全局方法挂载
Vue.prototype.$echarts = echarts //挂载到Vue实例上面
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.handleDict = handleDict
Vue.prototype.$ = jquery
Vue.prototype.timerConversion = timerConversion
Vue.prototype.dataURLtoBlob = dataURLtoBlob

Vue.prototype.msgSuccess = function (msg) {
    this.$message({ showClose: true, message: msg, type: 'success' })
}

Vue.prototype.msgError = function (msg) {
    this.$message({ showClose: true, message: msg, type: 'error' })
}

Vue.prototype.msgInfo = function (msg) {
    this.$message.info(msg)
}

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('DictTag', DictTag)
Vue.component('Editor', Editor)
Vue.component('UploadImage', UploadImage)
Vue.component('UploadFile', UploadFile)
Vue.component('HospitalName', HospitalName)
Vue.component('ZrDoc',ZrDoc)
Vue.use(htmlToPdf)
Vue.use(Print)
Vue.use(permission)
Vue.use(plugins)
Vue.use(Element, {
    size: Cookies.get('size') || 'small', // set element-ui default size
})
Vue.use(vueEsign)
Vue.config.productionTip = false

signalR.init(process.env.VUE_APP_SOCKET_API)
Vue.prototype.signalr = signalR

new Vue({
    el: '#app',
    router,
    store,
    render: (h) => h(App),
})
