import request from '@/utils/request'

//添加监测信息
export function postMessage(data) {
    console.log(data)
    return request({
        url: 'ai/getAnswer',
        method: 'get',
        params: data,
    })
}