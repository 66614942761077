// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"variables.scss"}]);
// Exports
exports.locals = {
	"menuColor": "#bfcbd9",
	"menuLightColor": "rgba(0, 0, 0, 0.7)",
	"menuColorActive": "#f4f4f5",
	"menuBackground": "#304156",
	"menuLightBackground": "#ffffff",
	"subMenuBackground": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "200px",
	"logoTitleColor": "#ffffff",
	"logoLightTitleColor": "#001529"
};
module.exports = exports;
