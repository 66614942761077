<template>
    <div>
        {{ currentName }}
    </div>
</template>

<script>
import { getDept } from "@/api/system/dept";
export default {
    name: "hospitalName",
    data() {
        return {
            currentName: "",
        }
    },
    mounted() {
        this.getName()
    },
    methods: {
        getName() {
            console.log(this.$store.getters.userinfo.deptId);
            if (this.$store.getters.userinfo.deptId != 0) {
                getDept(this.$store.getters.userinfo.deptId ).then(res => {
                    console.log(res.data.deptName)
                    this.currentName=res.data.deptName;
                })
            }else{
                this. currentName='上海臻贝医疗'
            }
        }
    }
}
</script>